import type {
  Context,
  Plugin,
} from '@nuxt/types';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { apiRetry } from '@@/shared/utilites/api-retry';
import { EnvironmentType } from '@@/shared/lib/utils/environmentUtils';
import { processAxiosInstance } from '@/plugins/axios';

let clientApiInstance: NuxtAxiosInstance | undefined;

const clientApiPlugin: Plugin = (ctx: Context) => {
  const {
    $axios,
    $config,
    req,
  } = ctx;

  clientApiInstance = $axios;
  processAxiosInstance(clientApiInstance, req);

  let endpoint = '';

  if ($config.ENVIRONMENT === EnvironmentType.Local) {
    if (process.client) {
      endpoint = `${ location.origin }`;
    } else {
      endpoint = 'https://lk.stranadev-new.com';
    }
  } else if (process.client) {
    endpoint = $config.CLIENT_API_URL;
  } else {
    endpoint = $config.SERVER_API_URL;
  }

  clientApiInstance.setBaseURL(endpoint);
  clientApiInstance.defaults.timeout = 45 * 1000;
  clientApiInstance.defaults.headers.platform = 'Broker'; // TODO: Нужен ли этот заголовок для запросов к бэку ЛК

  apiRetry(clientApiInstance, { retryCount: 5 });
};

export default clientApiPlugin;

export const useClientApi = (): NuxtAxiosInstance => {
  if (clientApiInstance == null) {
    throw new Error('Client API instance is not defined');
  }

  return clientApiInstance;
};
