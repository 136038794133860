import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { getCityOfSale } from '@@/shared/composables/cityOfSale';
import { errorByValueMessage } from '@@/shared/utilites/misc';
import { MENU_TYPE } from '@@/shared/constants/menuType';
import type {
  AcceptedDocument,
  DocumentDetail,
  DocumentSection,
} from '@/types/LegalDocuments';
import { legalDocumentsApiConfig } from '@/config/api/legalDocuments';
import { useClientApi } from '@/plugins/api/lk';

export const NO_SECTION_SLUG = 'no-section';

export const useLegalDocumentsStore = defineStore('legalDocuments', () => {
  const acceptedDocuments = ref<Array<AcceptedDocument>>([]);
  const documentDetail = ref<DocumentDetail>();
  const documentSections = ref<Array<DocumentSection>>([]);

  /**
   * Метод получение принятых документов
   * @param {Array<string>} slugs - слаги документов
   */
  async function loadAcceptedLegalDocuments (slugs: Array<string>): Promise<void> {
    const url = legalDocumentsApiConfig.accept;
    const { data } = await useClientApi().get<Array<AcceptedDocument>>(url, {
      params: {
        menu_type: MENU_TYPE.LK_BROKER,
        slug: slugs,
      },
    });
    acceptedDocuments.value = data;
  }

  /**
   * Метод принятия юр документов
   * @param {Array<string>} slugs - слаги документов
   */
  async function sendAcceptedLegalDocument (slugs: Array<string>) {
    const url = legalDocumentsApiConfig.accept;

    const { data } = await useClientApi().post(url, {
      menuType: MENU_TYPE.LK_BROKER,
      slugs,
    });
  }

  async function getDocumentsList (): Promise<void> {
    const cityOfSale = getCityOfSale();
    const url = legalDocumentsApiConfig.list;

    const {
      data,
    } = await useClientApi().get<Array<DocumentSection>>(url, {
      params: {
        city: cityOfSale?.slug,
        menu_type: MENU_TYPE.LK_BROKER,
      },
    });

    documentSections.value = data;
  }

  async function getDocumentDetail (slug: string, version?: number): Promise<DocumentDetail> {
    let citySlug = getCityOfSale()?.slug;
    if (!citySlug) {
      if (!documentSections.value.length) {
        await getDocumentsList();
      }
      // схватим первый попавшийся город, от него и будем дальше плясать
      citySlug = documentSections.value.find(section => section.slug === NO_SECTION_SLUG)?.documents.at(0)?.documentCities.at(0);
    }

    if (!citySlug) {
      throw new Error(errorByValueMessage({ citySlug }));
    }

    documentDetail.value = undefined;
    const url = legalDocumentsApiConfig.detail;
    const {
      data,
    } = await useClientApi().get<DocumentDetail>(url, {
      params: {
        city: citySlug,
        slug,
        version,
      },
    });

    documentDetail.value = data;

    return data;
  }

  const decoratedApiActions = decorateApiActions({
    loadAcceptedLegalDocuments,
    sendAcceptedLegalDocument,
    getDocumentsList,
  }, 'store/documents.ts');

  const rethrow = decorateApiActions({
    getDocumentDetail,
  }, 'store/documents.ts', true);

  return {
    acceptedDocuments,
    ...decoratedApiActions,
    ...rethrow,
    documentDetail,
    documentSections,
  };
});
