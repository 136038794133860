import { defineStore } from 'pinia';
import {
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import type { StateChanger } from 'vue-infinite-loading';
import type { MeetingResponse } from '@/types/Meeting';
import type { PageInfo } from '@/types/PageInfo';
import type { PaginatedItemsResponse } from '@/types/Response';
import { meetingsApiConfig } from '@/config/api/meetings';
import { useClientApi } from '@/plugins/api/lk';

type StagesKeys =
  'MEETING_SIGN_UP' |
  'MEETING_CANCEL' |
  'MEETING_RESCHEDULE' |
  'MEETING_ACTION_NOTIFICATION';

type Stage = {

  [_key in StagesKeys]: string;
};

const STAGES: Stage = {
  MEETING_SIGN_UP: 'MeetingSignUp',
  MEETING_CANCEL: 'MeetingCancel',
  MEETING_RESCHEDULE: 'MeetingReschedule',
  MEETING_ACTION_NOTIFICATION: 'MeetingActionNotification',
};

interface MeetingStatuses {
  sort: number;
  label: string;
  value: string;
}

type MeetingsResponse = PaginatedItemsResponse<MeetingResponse>;

interface MeetingsNextPageArgs {
  page: PageInfo | null;
  infiniteState: StateChanger;
}
interface MeetingsRequest {
  bookingId: string | number;
}

interface Slots {
  date: string;
  times: Array<string>;
}

interface State {
  stateMeetingAction?: string;
  meetings?: MeetingsResponse;
  meeting?: MeetingResponse;
  slots: Array<Slots>;
  statuses: Array<MeetingStatuses>;
  pageInfo: null | PageInfo;
}

interface Form {
  city: string;
  project: string;
  roomType: string;
  meetingType: string;
  date: string;
  time: string;
}

export const useMeetingsStore = defineStore('meetings', () => {
  const meetings = ref<State>({
    stateMeetingAction: undefined,
    meetings: undefined,
    meeting: undefined,
    slots: [],
    statuses: [],
    pageInfo: null,
  });
  const {
    $sentry,
  } = useContext();
  async function getMeetingStatuses (): Promise<void> {
    try {
      const url: string = meetingsApiConfig.statuses;
      const {
        data,
      } = await useClientApi().get<Array<MeetingStatuses>>(url);

      meetings.value.statuses = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeetingStatuses ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getMeeting (meetingId: string): Promise<void> {
    try {
      const url: string = meetingsApiConfig.meeting(meetingId);
      const {
        data,
      } = await useClientApi().get<MeetingResponse>(url);

      meetings.value.meeting = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getMeetings (meetingsRequest: MeetingsRequest): Promise<void> {
    const { bookingId } = meetingsRequest;
    try {
      const url: string = meetingsApiConfig.list;
      const {
        data,
      } = await useClientApi().get<MeetingsResponse>(url, { params: { booking_id: bookingId } });

      meetings.value.meetings = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getMeetings ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function nextPage ({ page, infiniteState }: MeetingsNextPageArgs): Promise<void> {
    try {
      if (!page?.next_page) {
        infiniteState.complete();

        return;
      }

      const response = await useClientApi().$get<MeetingsResponse>(page.next_page);

      if (!response.page_info) {
        throw new Error('page info not received');
      }

      if (response.result?.length) {
        meetings.value.pageInfo = response.page_info;

        if (meetings.value.meetings?.result) {
          meetings.value.meetings.result = [...meetings.value.meetings.result, ...response.result];
        }

        if (!meetings.value.pageInfo?.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      infiniteState.complete();
      throw error;
    }
  }

  async function getSlots ({
    meet,
    city,
    project,
  }: {
    meet: string;
    city: string;
    project: string;
  }): Promise<void> {
    try {
      const url: string = meetingsApiConfig.slots;
      const {
        data,
      } = await useClientApi().get(url, {
        params: {
          meet,
          city,
          project,
        },
      });

      meetings.value.slots = data;
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ getSlots ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function signUpMeeting ({
    form,
    bookingId,
  }: {
    form: Form;
    bookingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time,
        roomType,
      } = form;

      const url = meetingsApiConfig.list;

      const {
        data,
      } = await useClientApi().post(url, {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`,
        propertyType: roomType,
        bookingId: Number(bookingId),
      });

      await getMeeting(data.id);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ signUpMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function rescheduleMeeting ({
    form,
    meetingId,
  }: {
    form: Form;
    meetingId: string;
  }): Promise<void> {
    try {
      const {
        city,
        project,
        meetingType,
        date,
        time,
      } = form;

      const url = meetingsApiConfig.meeting(meetingId);

      await useClientApi().patch(url, {
        cityId: city,
        projectId: project,
        type: meetingType,
        topic: '',
        date: `${ date }T${ time }Z`,
      });

      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ rescheduleMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function cancelMeeting (meetingId: string): Promise<void> {
    try {
      const url = meetingsApiConfig.refuse(meetingId);
      await useClientApi().patch(url);
      await getMeeting(meetingId);
    } catch (error) {
      console.log('🚀 ~ file: meetings.ts ~ cancelMeeting ~ error', error);
      $sentry.captureException(error);
    }
  }

  function setStage (stage: StagesKeys): void {
    meetings.value.stateMeetingAction = STAGES[stage];
  }

  return {
    meetings,
    nextPage,
    getMeetingStatuses,
    getMeeting,
    getMeetings,
    getSlots,
    signUpMeeting,
    rescheduleMeeting,
    cancelMeeting,
    setStage,
  };
},
);
