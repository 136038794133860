import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import { propertiesApiConfig } from '@/config/api/properties';
import { useClientApi } from '@/plugins/api/lk';
import { useSentry } from '@/plugins/sentry';

type PaymentMethodKeys = 'cash' | 'mortgage' | 'mortgageByDev';
interface Discount {
  percent: number;
  discount: number;
  priceWithDiscount: number;
}

type DiscountResponse = Record<PaymentMethodKeys, Discount>;

interface PayloadGetDiscount {
  projectSlug: string;
  price: number;
  originalPrice: number;
}

export const useDiscountStore = defineStore('discount', () => {
  const discount = ref<null | DiscountResponse>(null);

  async function getDiscountByProject (payload: PayloadGetDiscount): Promise<void> {
    try {
      const url: string = propertiesApiConfig.prices;
      const {
        data,
      } = await useClientApi().get<DiscountResponse>(url, {
        params: {
          ...payload,
        },
      });

      discount.value = data;
    } catch (error) {
      console.error('🚀 ~ file: discount.ts ~ getDiscountByProject ~ e', error);
      useSentry().captureException(error);
    }
  }

  return {
    discount,
    getDiscountByProject,
  };
});
