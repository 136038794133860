import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { useCalendarFiltersStore } from '@/store/calendar/filters';
import type { CalendarFilters } from '@/store/calendar/filters';

import type { CalendarEventResponse } from '@/types/CalendarEvent';
import { useClientApi } from '@/plugins/api/lk';

interface EventsResponse {
  count: number;
  recordCount: number;
  result: Array<CalendarEventResponse>;
}

interface State {
  events: Array<CalendarEventResponse>;
  count?: number;
}

export const useCalendarStore = defineStore('calendar', {
  state: (): State => ({
    events: [],
    count: undefined,
  }),

  getters: {},

  actions: {
    async getEvents (): Promise<void> {
      try {
        const { filters } = storeToRefs(useCalendarFiltersStore());

        if (!filters?.value) {
          return;
        }

        const requestFilters: Partial<CalendarFilters> = {};
        for (const [key, value] of Object.entries(filters.value)) {
          if (value !== undefined && value !== 'all') {
            requestFilters[key as keyof CalendarFilters] = value;
          }
        }

        const url = 'api/calendar';
        const {
          data,
        } = await useClientApi().get<EventsResponse>(url, {
          params: {
            ...requestFilters,
          },
        });

        this.events = data.result;
        this.count = data.count;
      } catch (error) {
        console.log('🚀 ~ file: calendar.ts ~ getEvents ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },
  },
});
