import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm$manager$photo, _vm$manager, _vm$manager2, _vm$manager3, _vm$manager4, _vm$manager5, _vm$manager6, _vm$manager7;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.managerInfo
  }, [_c('div', {
    class: _vm.$style.avatarWrapper
  }, [_c('img', {
    class: _vm.$style.avatar,
    attrs: {
      "src": (_vm$manager$photo = _vm.manager.photo) === null || _vm$manager$photo === void 0 ? void 0 : _vm$manager$photo.aws,
      "alt": "Фото менеджера"
    }
  })]), _vm._v(" "), _c('div', {
    class: _vm.$style.rightSide
  }, [_c('div', {
    class: _vm.$style.dataColumn
  }, [_c('span', {
    class: _vm.$style.managerName
  }, [_vm._v(" " + _vm._s((_vm$manager = _vm.manager) === null || _vm$manager === void 0 ? void 0 : _vm$manager.lastname) + " " + _vm._s((_vm$manager2 = _vm.manager) === null || _vm$manager2 === void 0 ? void 0 : _vm$manager2.name))]), _vm._v(" "), _c('span', {
    class: _vm.$style.managerPosition
  }, [_vm._v(_vm._s((_vm$manager3 = _vm.manager) === null || _vm$manager3 === void 0 ? void 0 : _vm$manager3.position))])]), _vm._v(" "), _c('dl', {
    class: _vm.$style.dataColumn
  }, [_c('dt', {
    class: _vm.$style.phoneTitle
  }, [_vm._v("Телефон")]), _vm._v(" "), _c('dd', {
    class: _vm.$style.phoneLink
  }, [_setup.isMobile ? _c('a', {
    attrs: {
      "href": "tel:".concat((_vm$manager4 = _vm.manager) === null || _vm$manager4 === void 0 ? void 0 : _vm$manager4.phone)
    }
  }, [_c(_setup.PhoneOutput, {
    attrs: {
      "input": (_vm$manager5 = _vm.manager) === null || _vm$manager5 === void 0 ? void 0 : _vm$manager5.phone
    }
  })], 1) : _c(_setup.PhoneOutput, {
    attrs: {
      "input": (_vm$manager6 = _vm.manager) === null || _vm$manager6 === void 0 ? void 0 : _vm$manager6.phone
    }
  })], 1)]), _vm._v(" "), _c('dl', {
    class: _vm.$style.dataColumn
  }, [_c('dt', {
    class: _vm.$style.scheduleTitle
  }, [_vm._v("Режим работы")]), _vm._v(" "), _c('dd', {
    class: _vm.$style.scheduleValue
  }, [_vm._v(_vm._s((_vm$manager7 = _vm.manager) === null || _vm$manager7 === void 0 ? void 0 : _vm$manager7.work_schedule))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };