const legalDocumentsApi = {
  legalDocuments: {
    accept: 'api/legal_documents/accept',
    list: 'api/legal_documents/list',
    detail: 'api/legal_documents/detail',
  },
};

export default legalDocumentsApi;

export const legalDocumentsApiConfig = legalDocumentsApi.legalDocuments;
