import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { prettyPhoneWithParenthesis } from '@strana-artw/cabinets-common';
import { SButton, SIcon } from '@strana-artw/ui-kit';
import { useMainPageStore } from '@/store/mainPage';
export default {
  name: 'HeaderWithoutLogin',
  components: {
    SIcon: SIcon,
    SButton: SButton
  },
  props: {
    isLoginButtonActive: {
      type: Boolean,
      default: false
    },
    isBackLinkActive: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup() {
    var mainPageStore = useMainPageStore();
    var getManager = mainPageStore.getManager;
    var _storeToRefs = storeToRefs(mainPageStore),
      manager = _storeToRefs.manager;
    onMounted(function () {
      getManager();
    });
    var phone = computed(function () {
      var _manager$value;
      return (_manager$value = manager.value) === null || _manager$value === void 0 ? void 0 : _manager$value.phone;
    });
    return {
      getManager: getManager,
      phone: phone
    };
  },
  computed: {
    link: function link() {
      return "//".concat(process.env.HOST);
    },
    preparedPhone: function preparedPhone() {
      if (!this.phone) {
        return;
      }
      return prettyPhoneWithParenthesis(this.phone);
    }
  }
};