import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../lk_broker/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_0c8eb7d0 from 'nuxt_plugin_plugin_0c8eb7d0' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_smoothscrollpolyfill_72a4289e from 'nuxt_plugin_smoothscrollpolyfill_72a4289e' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_intersectionobserver_5167c258 from 'nuxt_plugin_intersectionobserver_5167c258' // Source: ./nuxt-polyfill/intersection-observer.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_70cffde4 from 'nuxt_plugin_nuxtsvgsprite_70cffde4' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_portalvue_2f63a5cd from 'nuxt_plugin_portalvue_2f63a5cd' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_goToplugin_19d12a42 from 'nuxt_plugin_goToplugin_19d12a42' // Source: ./goTo-plugin.js (mode: 'all')
import nuxt_plugin_notify_4f7d1229 from 'nuxt_plugin_notify_4f7d1229' // Source: ./notify.js (mode: 'all')
import nuxt_plugin_platformplugin_0d2b7f4f from 'nuxt_plugin_platformplugin_0d2b7f4f' // Source: ./platform-plugin.js (mode: 'all')
import nuxt_plugin_distplugin5cd6bd3c_02162cdc from 'nuxt_plugin_distplugin5cd6bd3c_02162cdc' // Source: ./dist.plugin.5cd6bd3c.mjs (mode: 'all')
import nuxt_plugin_index_503e82e5 from 'nuxt_plugin_index_503e82e5' // Source: ../common/directives/index.js (mode: 'all')
import nuxt_plugin_pinia_f245ae0c from 'nuxt_plugin_pinia_f245ae0c' // Source: ../common/plugins/pinia.ts (mode: 'all')
import nuxt_plugin_mockadapter_757e8766 from 'nuxt_plugin_mockadapter_757e8766' // Source: ../common/plugins/mock-adapter (mode: 'all')
import nuxt_plugin_api_22834091 from 'nuxt_plugin_api_22834091' // Source: ../lk_broker/plugins/api.js (mode: 'all')
import nuxt_plugin_scrollbar_8ae4bbf6 from 'nuxt_plugin_scrollbar_8ae4bbf6' // Source: ../lk_broker/plugins/scrollbar.js (mode: 'all')
import nuxt_plugin_sentry_40a2c6d3 from 'nuxt_plugin_sentry_40a2c6d3' // Source: ../lk_broker/plugins/sentry (mode: 'all')
import nuxt_plugin_modal_32a3a754 from 'nuxt_plugin_modal_32a3a754' // Source: ../lk_broker/plugins/modal.ts (mode: 'all')
import nuxt_plugin_contextMenu_a427cd8c from 'nuxt_plugin_contextMenu_a427cd8c' // Source: ../lk_broker/plugins/contextMenu (mode: 'all')
import nuxt_plugin_clickOutside_0aede166 from 'nuxt_plugin_clickOutside_0aede166' // Source: ../lk_broker/plugins/clickOutside.js (mode: 'all')
import nuxt_plugin_intersect_4fb49728 from 'nuxt_plugin_intersect_4fb49728' // Source: ../lk_broker/plugins/intersect.js (mode: 'all')
import nuxt_plugin_calendarclient_4b87a890 from 'nuxt_plugin_calendarclient_4b87a890' // Source: ../lk_broker/plugins/calendar.client.js (mode: 'client')
import nuxt_plugin_portal_1401da16 from 'nuxt_plugin_portal_1401da16' // Source: ../lk_broker/plugins/api/portal (mode: 'all')
import nuxt_plugin_services_cd69c6b2 from 'nuxt_plugin_services_cd69c6b2' // Source: ../lk_broker/plugins/api/services (mode: 'all')
import nuxt_plugin_loyalty_12b6ea46 from 'nuxt_plugin_loyalty_12b6ea46' // Source: ../lk_broker/plugins/api/loyalty (mode: 'all')
import nuxt_plugin_lk_22834488 from 'nuxt_plugin_lk_22834488' // Source: ../lk_broker/plugins/api/lk (mode: 'all')
import nuxt_plugin_appcontext_5816ab8f from 'nuxt_plugin_appcontext_5816ab8f' // Source: ../lk_broker/plugins/app-context (mode: 'all')
import nuxt_plugin_smodal_74161c5f from 'nuxt_plugin_smodal_74161c5f' // Source: ../lk_broker/plugins/s-modal (mode: 'all')
import nuxt_plugin_vuescreen_3f00d8f2 from 'nuxt_plugin_vuescreen_3f00d8f2' // Source: ../lk_broker/plugins/vue-screen (mode: 'all')
import nuxt_plugin_portalcomponent_2d758260 from 'nuxt_plugin_portalcomponent_2d758260' // Source: ../lk_broker/plugins/portal-component (mode: 'all')
import nuxt_plugin_index_83cd480a from 'nuxt_plugin_index_83cd480a' // Source: ../lk_broker/plugins/modal/index.js (mode: 'all')
import nuxt_plugin_globalErrorHandler_62604efe from 'nuxt_plugin_globalErrorHandler_62604efe' // Source: ../lk_broker/plugins/globalErrorHandler.js (mode: 'all')
import nuxt_plugin_chessboard_d8ffa41e from 'nuxt_plugin_chessboard_d8ffa41e' // Source: ../lk_broker/plugins/pinia/chessboard.ts (mode: 'all')
import nuxt_plugin_polyfills_9a521cc6 from 'nuxt_plugin_polyfills_9a521cc6' // Source: ../lk_broker/plugins/polyfills.ts (mode: 'all')
import nuxt_plugin_globalStoreclient_52687b1e from 'nuxt_plugin_globalStoreclient_52687b1e' // Source: ../lk_broker/plugins/globalStore.client.ts (mode: 'client')
import nuxt_plugin_globalHead_7a917c0c from 'nuxt_plugin_globalHead_7a917c0c' // Source: ../lk_broker/app/plugins/globalHead.server (mode: 'server')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_plugin_1ea28f65 from 'nuxt_plugin_plugin_1ea28f65' // Source: ../lk_broker/config/auth/plugin.js (mode: 'all')
import nuxt_plugin_meta_b5aab5ac from 'nuxt_plugin_meta_b5aab5ac' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ru","data-ios-should-lock-scroll":true},"bodyAttrs":{"data-ios-should-lock-scroll":true},"titleTemplate":"%s - Страна Девелопмент","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#fff"},{"name":"theme-color","content":"#fff"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicons\u002Ffavicon.ico"},{"rel":"icon","type":"image\u002Fsvg+xml","href":"\u002Ffavicons\u002Ffavicon.svg"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicons\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicons\u002Ffavicon-16x16.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Ffavicons\u002Fapple-touch-icon.png"},{"rel":"manifest","href":"\u002Ffavicons\u002Fsite.webmanifest"},{"rel":"preload","as":"font","href":"\u002Ffonts\u002FMazzard-Medium.woff","type":"font\u002Fwoff","crossorigin":true},{"rel":"preload","as":"font","href":"\u002Ffonts\u002FMazzard-MediumM.woff","type":"font\u002Fwoff","crossorigin":true},{"rel":"preload","as":"font","href":"\u002Ffonts\u002FMazzard-Regular.woff","type":"font\u002Fwoff","crossorigin":true},{"rel":"preload","as":"font","href":"\u002Ffonts\u002FStranaFont-Regular.woff","type":"font\u002Fwoff","crossorigin":true}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0c8eb7d0 === 'function') {
    await nuxt_plugin_plugin_0c8eb7d0(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_72a4289e === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_72a4289e(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectionobserver_5167c258 === 'function') {
    await nuxt_plugin_intersectionobserver_5167c258(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_70cffde4 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_70cffde4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_2f63a5cd === 'function') {
    await nuxt_plugin_portalvue_2f63a5cd(app.context, inject)
  }

  if (typeof nuxt_plugin_goToplugin_19d12a42 === 'function') {
    await nuxt_plugin_goToplugin_19d12a42(app.context, inject)
  }

  if (typeof nuxt_plugin_notify_4f7d1229 === 'function') {
    await nuxt_plugin_notify_4f7d1229(app.context, inject)
  }

  if (typeof nuxt_plugin_platformplugin_0d2b7f4f === 'function') {
    await nuxt_plugin_platformplugin_0d2b7f4f(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin5cd6bd3c_02162cdc === 'function') {
    await nuxt_plugin_distplugin5cd6bd3c_02162cdc(app.context, inject)
  }

  if (typeof nuxt_plugin_index_503e82e5 === 'function') {
    await nuxt_plugin_index_503e82e5(app.context, inject)
  }

  if (typeof nuxt_plugin_pinia_f245ae0c === 'function') {
    await nuxt_plugin_pinia_f245ae0c(app.context, inject)
  }

  if (typeof nuxt_plugin_mockadapter_757e8766 === 'function') {
    await nuxt_plugin_mockadapter_757e8766(app.context, inject)
  }

  if (typeof nuxt_plugin_api_22834091 === 'function') {
    await nuxt_plugin_api_22834091(app.context, inject)
  }

  if (typeof nuxt_plugin_scrollbar_8ae4bbf6 === 'function') {
    await nuxt_plugin_scrollbar_8ae4bbf6(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_40a2c6d3 === 'function') {
    await nuxt_plugin_sentry_40a2c6d3(app.context, inject)
  }

  if (typeof nuxt_plugin_modal_32a3a754 === 'function') {
    await nuxt_plugin_modal_32a3a754(app.context, inject)
  }

  if (typeof nuxt_plugin_contextMenu_a427cd8c === 'function') {
    await nuxt_plugin_contextMenu_a427cd8c(app.context, inject)
  }

  if (typeof nuxt_plugin_clickOutside_0aede166 === 'function') {
    await nuxt_plugin_clickOutside_0aede166(app.context, inject)
  }

  if (typeof nuxt_plugin_intersect_4fb49728 === 'function') {
    await nuxt_plugin_intersect_4fb49728(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_calendarclient_4b87a890 === 'function') {
    await nuxt_plugin_calendarclient_4b87a890(app.context, inject)
  }

  if (typeof nuxt_plugin_portal_1401da16 === 'function') {
    await nuxt_plugin_portal_1401da16(app.context, inject)
  }

  if (typeof nuxt_plugin_services_cd69c6b2 === 'function') {
    await nuxt_plugin_services_cd69c6b2(app.context, inject)
  }

  if (typeof nuxt_plugin_loyalty_12b6ea46 === 'function') {
    await nuxt_plugin_loyalty_12b6ea46(app.context, inject)
  }

  if (typeof nuxt_plugin_lk_22834488 === 'function') {
    await nuxt_plugin_lk_22834488(app.context, inject)
  }

  if (typeof nuxt_plugin_appcontext_5816ab8f === 'function') {
    await nuxt_plugin_appcontext_5816ab8f(app.context, inject)
  }

  if (typeof nuxt_plugin_smodal_74161c5f === 'function') {
    await nuxt_plugin_smodal_74161c5f(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescreen_3f00d8f2 === 'function') {
    await nuxt_plugin_vuescreen_3f00d8f2(app.context, inject)
  }

  if (typeof nuxt_plugin_portalcomponent_2d758260 === 'function') {
    await nuxt_plugin_portalcomponent_2d758260(app.context, inject)
  }

  if (typeof nuxt_plugin_index_83cd480a === 'function') {
    await nuxt_plugin_index_83cd480a(app.context, inject)
  }

  if (typeof nuxt_plugin_globalErrorHandler_62604efe === 'function') {
    await nuxt_plugin_globalErrorHandler_62604efe(app.context, inject)
  }

  if (typeof nuxt_plugin_chessboard_d8ffa41e === 'function') {
    await nuxt_plugin_chessboard_d8ffa41e(app.context, inject)
  }

  if (typeof nuxt_plugin_polyfills_9a521cc6 === 'function') {
    await nuxt_plugin_polyfills_9a521cc6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalStoreclient_52687b1e === 'function') {
    await nuxt_plugin_globalStoreclient_52687b1e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_globalHead_7a917c0c === 'function') {
    await nuxt_plugin_globalHead_7a917c0c(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_1ea28f65 === 'function') {
    await nuxt_plugin_plugin_1ea28f65(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_b5aab5ac === 'function') {
    await nuxt_plugin_meta_b5aab5ac(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
