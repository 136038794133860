import { SMortgageAxiosConfig } from '@strana-artw/mortgage';
import type {
  Context,
  Plugin,
} from '@nuxt/types';
import type { Inject } from '@nuxt/types/app';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';

let servicesApiInstance: NuxtAxiosInstance | undefined;

const servicesApiPlugin: Plugin = (ctx: Context, inject: Inject) => {
  const {
    $axios,
    $config,
  } = ctx;

  let API_URL;
  let API_SUFFIX = '';

  if ($config.ENVIRONMENT === 'local') {
    API_URL = process.client ? location.origin : `https://${ $config.SERVICES_SITE_HOST }`;
    API_SUFFIX = '/services';
  } else {
    API_URL = `https://${ $config.SERVICES_SITE_HOST }`;
  }

  const API_PATH = `/api${ API_SUFFIX }/v1`;
  API_URL += API_PATH;
  servicesApiInstance = $axios.create({
    ...SMortgageAxiosConfig,
    baseURL: API_URL,
  });

  inject('servicesApi', servicesApiInstance);
};

export default servicesApiPlugin;

export const useServicesApi = (): NuxtAxiosInstance => servicesApiInstance as NuxtAxiosInstance;
