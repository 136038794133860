import { defineStore } from 'pinia';
import { useFetch } from '@nuxtjs/composition-api';
import { ref } from 'vue';
import axios from 'axios';
import { useVuexStore } from '@/plugins/app-context';
import { useSentry } from '@/plugins/sentry';
import { useClientApi } from '@/plugins/api/lk';
import { accountApiConfig } from '@/config/api/account';

export interface GetManagerResponseItem {
  email: string;
  name: string;
  lastname: string;
  patronymic: string;
  position: string;
  phone: string;

  work_schedule: string;
  photo: {
    s3: string;
    aws: string;
    src: string;
  };
  city: {
    value: string;
    label: string;
  };
}
interface GetManagerResponse {
  result: Array<GetManagerResponseItem>;
}

export const useHelpManager = defineStore('help-manager', () => {
  const defaultCity = 'tmn';
  const manager = ref<GetManagerResponseItem | undefined>(undefined);
  const managers = ref<Array<GetManagerResponseItem>>([]);

  // FIXME $auth и прочий стор на pinia
  async function getUserCity () {
    return useVuexStore().$auth?.$state?.user?.city_of_sale.slug;
  }

  async function fetchAllManagers (citySlug: string): Promise<GetManagerResponse> {
    return useClientApi().$get<GetManagerResponse>(accountApiConfig.managers.getManager(citySlug));
  }

  function fetchManager (): GetManagerResponseItem | undefined {
    if (!managers.value?.length) {
      return undefined;
    }
    const randomIndex = Math.floor(Math.random() * managers.value.length);

    return managers.value[randomIndex];
  }

  const isLoading = ref(false);

  useFetch(async () => {
    isLoading.value = true;

    const city = await getUserCity();
    try {
      managers.value = (await fetchAllManagers(city)).result;
      manager.value = fetchManager();
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        managers.value = (await fetchAllManagers(defaultCity)).result;
        manager.value = fetchManager();
      } else {
        console.log('🚀 ~ helpManager.ts ~~ fetch ~ error', error);
        useSentry().captureException(error);
      }
    } finally {
      isLoading.value = false;
    }
  });

  return {
    managers,
    manager,
    isLoading,
  };
});
