import { useAuthStore } from '@@/lk_broker/store/authStore';
import type { Role } from '@@/lk_broker/store/authStore';
import { toRefs } from 'vue';

export const useUserRole = (): Role => {
  const authStore = useAuthStore();
  const { role } = toRefs(authStore);

  if (!role.value) {
    throw new Error(`User role is not defined: ${ role.value } `);
  }

  return role.value;

  /* TODO: Добавить проверку на агента/представителя */
};
