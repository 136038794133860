import { defineStore } from 'pinia';

import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';

import { useNotify } from '@@/common/plugins/notify';
import { useUserRole } from '@@/shared/composables/useUserRole';
import type {
  Agreement,
  Document,
} from '@/types/Agreements';
import { useClientApi } from '@/plugins/api/lk';
import { additionalApiConfig } from '@/config/api/additionals';

interface State {
  currentAdditionalAgreement: Agreement | object;
}

async function notifyError (error: unknown, fallbackMessage?: string): Promise<void> {
  const notifier = useNotify();

  if (!notifier) {
    return;
  }

  const message = getErrorMessageTyped(error, fallbackMessage);

  notifier.create({
    message,
    type: 'negative',
  });

  throw new Error(message);
}

export const useAdditionalAgreementStore = defineStore('additionalAgreement', {
  state: (): State => ({
    currentAdditionalAgreement: {},
  }),

  getters: {},

  actions: {
    async getAdditionalAgreement (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const url = additionalApiConfig.additionalAgreementById(role, id);

        const {
          data,
        } = await useClientApi().get<Agreement>(url);

        this.currentAdditionalAgreement = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: additionalAgreement.ts ~ getAdditionalAgreement ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getAdditionalAgreementDocument (id: number): Promise<void> {
      const fallbackMessage = 'Не удалось получить документ';
      let timer: ReturnType<typeof setTimeout>;

      try {
        const role = useUserRole();
        const url = additionalApiConfig.getDocument(role, id);

        const {
          data,
        } = await useClientApi().get<Document>(url);

        const {
          aws: link,
        } = data.files?.[0]?.files?.[0] ?? {};

        if (!link) {
          throw new Error(fallbackMessage);
        }

        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        document.body.appendChild(a);
        // FIXME если сразу открывать ссылки, то иногда падает ошибка strana_lk-1379
        // добавлен таймаут
        timer = setTimeout(() => {
          a.click();
          document.body.removeChild(a);
          clearTimeout(timer);
        }, 500);
      } catch (error: unknown) {
        console.log('🚀 ~ file: additionalAgreement.ts ~ getAdditionalAgreementDocument ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error, fallbackMessage);
      }
    },
  },
});
