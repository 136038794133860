/**
 Запросы на сервис лояльности
 */
import type {
  Context,
  Plugin,
} from '@nuxt/types';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { apiRetry } from '@@/shared/utilites/api-retry';
import { processAxiosInstance } from '@/plugins/axios';

let loyaltyApiInstance: NuxtAxiosInstance | undefined;

const loyaltyApiPlugin: Plugin = (ctx: Context) => {
  const {
    $axios,
    $config,
    req,
  } = ctx;

  const API_URL = `https://${ $config.SERVICES_SITE_HOST }/loyalty/api`;

  loyaltyApiInstance = $axios;

  processAxiosInstance(loyaltyApiInstance, req);

  loyaltyApiInstance.setBaseURL(API_URL);
  loyaltyApiInstance.defaults.timeout = 45 * 1000;
  loyaltyApiInstance.defaults.withCredentials = false;

  apiRetry(loyaltyApiInstance, { retryCount: 5 });
};

export default loyaltyApiPlugin;

export const useLoyaltyApi = (): NuxtAxiosInstance => {
  if (loyaltyApiInstance == null) {
    throw new Error('Loyalty API instance is not defined');
  }

  return loyaltyApiInstance;
};
