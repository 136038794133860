import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useClientApi } from '@/plugins/api/lk';
import { accountApiConfig } from '@/config/api/account';
import { useSentry } from '@/plugins/sentry';

interface ConsultationResponse {
  text: string;
  slug: string;
}

interface Consultation {
  text: string;
  value: string;
}

interface State {
  consultationTypes: Array<Consultation>;
}

export const useAccountStore = defineStore('account', () => {
  const consultationTypes = ref<Array<Consultation>>([]);

  async function resetPassword (email: string): Promise<void> {
    try {
      const url = accountApiConfig.resetPassword;
      await useClientApi().post(url, {
        email,
      });
    } catch (error: unknown) {
      console.error('🚀 ~ file: account.ts ~ resetPassword ~ error', error);
      useSentry().captureException(error);
      throw error;
    }
  }

  async function getConsultationTypes (): Promise<void> {
    try {
      const url = accountApiConfig.consultationTypes;
      const {
        data,
      } = await useClientApi().get<Array<ConsultationResponse>>(url);

      consultationTypes.value = normalizeConsultations(data);
    } catch (error: unknown) {
      console.error('🚀 ~ file: account.ts ~ getConsultationTypes ~ e', error);
      useSentry().captureException(error);

      throw error;
    }
  }

  return {
    consultationTypes,
    getConsultationTypes,
    resetPassword,
  };
});

function normalizeConsultations (response: Array<ConsultationResponse>): Array<Consultation> {
  if (!response || !Array.isArray(response)) {
    return [];
  }

  return response
    .filter(item => item)
    .map(({ text, slug }) => ({
      text: capitalizeFirstLetter(text),
      value: slug,
    }));
}

function capitalizeFirstLetter (string: string): string {
  if (!string?.length) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}
