import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.timers.js";
// import VMenuTransition from '../../transitions/VMenuTransition';
// Components
// import VThemeProvider from '../VThemeProvider';

// Mixins
import Activatable from '../../../mixins/activatable';
import Delayable from '../../../mixins/delayable';
import Dependent from '../../../mixins/dependent';
import Menuable from '../../../mixins/menuable';
import Returnable from '../../../mixins/returnable';
import Roundable from '../../../mixins/roundable';
import Toggleable from '../../../mixins/toggleable';
import Themeable from '../../../mixins/themeable';

// Directives
import ClickOutside from '../../../directives/click-outside';
import Resize from '../../../directives/resize';

// Utilities
import mixins from '../../../utils/mixins';
import { removed } from '../../../utils/console';
import { convertToUnit, keyCodes } from '../../../utils/helpers';
var baseMixins = mixins(Dependent, Delayable, Menuable, Returnable, Roundable, Toggleable, Themeable);
/* eslint-disable radix,no-unused-expressions,no-prototype-builtins */
export default baseMixins.extend({
  name: 'VMenu',
  directives: {
    ClickOutside: ClickOutside,
    Resize: Resize
  },
  provide: function provide() {
    return {
      isInMenu: true,
      // Pass theme through to default slot
      theme: this.theme
    };
  },
  props: {
    auto: Boolean,
    closeOnClick: {
      type: Boolean,
      default: true
    },
    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    disableKeys: Boolean,
    maxHeight: {
      type: [Number, String],
      default: 'auto'
    },
    offsetX: Boolean,
    offsetY: Boolean,
    openOnClick: {
      type: Boolean,
      default: true
    },
    openOnHover: Boolean,
    origin: {
      type: String,
      default: 'top left'
    },
    transition: {
      type: [Boolean, String],
      default: 'v-menu-transition'
    },
    borderRadius: {
      type: Number,
      default: 16
    },
    enablePerfectScrollBar: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      calculatedTopAuto: 0,
      defaultOffset: 8,
      hasJustFocused: false,
      listIndex: -1,
      resizeTimeout: 0,
      selectedIndex: null,
      tiles: []
    };
  },
  computed: {
    activeTile: function activeTile() {
      return this.tiles[this.listIndex];
    },
    calculatedLeft: function calculatedLeft() {
      var menuWidth = Math.max(this.dimensions.content.width, parseFloat(this.calculatedMinWidth));
      if (!this.auto) {
        return this.calcLeft(menuWidth) || '0';
      }
      return convertToUnit(this.calcXOverflow(this.calcLeftAuto(), menuWidth)) || '0';
    },
    calculatedMaxHeight: function calculatedMaxHeight() {
      var height = this.auto ? 200 : this.maxHeight;
      return height || '0';
    },
    calculatedMaxWidth: function calculatedMaxWidth() {
      return convertToUnit(this.maxWidth) || '0';
    },
    calculatedMinWidth: function calculatedMinWidth() {
      if (this.minWidth) {
        return convertToUnit(this.minWidth) || '0';
      }
      var minWidth = Math.min(this.dimensions.activator.width + Number(this.nudgeWidth) + (this.auto ? 16 : 0), Math.max(this.pageWidth - 24, 0));
      var calculatedMaxWidth = isNaN(parseInt(this.calculatedMaxWidth)) ? minWidth : parseInt(this.calculatedMaxWidth);
      return convertToUnit(Math.min(calculatedMaxWidth, minWidth)) || '0';
    },
    calculatedTop: function calculatedTop() {
      var top = !this.auto ? this.calcTop() : convertToUnit(this.calcYOverflow(this.calculatedTopAuto));
      return top || '0';
    },
    hasClickableTiles: function hasClickableTiles() {
      return Boolean(this.tiles.find(function (tile) {
        return tile.tabIndex > -1;
      }));
    },
    styles: function styles() {
      return {
        maxHeight: convertToUnit(this.calculatedMaxHeight),
        minWidth: this.calculatedMinWidth,
        maxWidth: this.calculatedMaxWidth,
        top: this.calculatedTop,
        left: this.calculatedLeft,
        transformOrigin: this.origin,
        zIndex: this.zIndex || this.activeZIndex,
        borderRadius: convertToUnit(this.borderRadius)
      };
    }
  },
  watch: {
    isActive: function isActive(val) {
      if (!val) {
        this.listIndex = -1;
      }
    },
    isContentActive: function isContentActive(val) {
      this.hasJustFocused = val;
    },
    listIndex: function listIndex(next, prev) {
      if (next in this.tiles) {
        var tile = this.tiles[next];
        tile.classList.add('v-list-item--highlighted');
        this.$refs.content.scrollTop = tile.offsetTop - tile.clientHeight;
      }
      prev in this.tiles && this.tiles[prev].classList.remove('v-list-item--highlighted');
    }
  },
  created: function created() {
    /* istanbul ignore next */
    if (this.$attrs.hasOwnProperty('full-width')) {
      removed('full-width', this);
    }
  },
  mounted: function mounted() {
    this.isActive && this.callActivate();
  },
  methods: {
    activate: function activate() {
      var _this = this;
      // Update coordinates and dimensions of menu
      // and its activator
      this.updateDimensions();
      // Start the transition
      requestAnimationFrame(function () {
        // Once transitioning, calculate scroll and top position
        _this.startTransition().then(function () {
          if (_this.$refs.content) {
            _this.calculatedTopAuto = _this.calcTopAuto();
            _this.auto && (_this.$refs.content.scrollTop = _this.calcScrollPosition());
          }
        });
      });
    },
    calcScrollPosition: function calcScrollPosition() {
      var $el = this.$refs.content;
      var activeTile = $el.querySelector('.v-list-item--active');
      var maxScrollTop = $el.scrollHeight - $el.offsetHeight;
      return activeTile ? Math.min(maxScrollTop, Math.max(0, activeTile.offsetTop - $el.offsetHeight / 2 + activeTile.offsetHeight / 2)) : $el.scrollTop;
    },
    calcLeftAuto: function calcLeftAuto() {
      return parseInt(this.dimensions.activator.left - this.defaultOffset * 2);
    },
    calcTopAuto: function calcTopAuto() {
      var $el = this.$refs.content;
      var activeTile = $el.querySelector('.v-list-item--active');
      if (!activeTile) {
        this.selectedIndex = null;
      }
      if (this.offsetY || !activeTile) {
        return this.computedTop;
      }
      this.selectedIndex = Array.from(this.tiles).indexOf(activeTile);
      var tileDistanceFromMenuTop = activeTile.offsetTop - this.calcScrollPosition();
      var firstTileOffsetTop = $el.querySelector('.v-list-item').offsetTop;
      return this.computedTop - tileDistanceFromMenuTop - firstTileOffsetTop - 1;
    },
    changeListIndex: function changeListIndex(e) {
      // For infinite scroll and autocomplete, re-evaluate children
      this.getTiles();
      if (!this.isActive || !this.hasClickableTiles) {
        return;
      } else if (e.keyCode === keyCodes.tab) {
        this.isActive = false;
        return;
      } else if (e.keyCode === keyCodes.down) {
        this.nextTile();
      } else if (e.keyCode === keyCodes.up) {
        this.prevTile();
      } else if (e.keyCode === keyCodes.end) {
        this.lastTile();
      } else if (e.keyCode === keyCodes.home) {
        this.firstTile();
      } else if (e.keyCode === keyCodes.enter && this.listIndex !== -1) {
        this.tiles[this.listIndex].click();
      } else {
        return;
      }
      // One of the conditions was met, prevent default action (#2988)
      e.preventDefault();
    },
    closeConditional: function closeConditional(e) {
      var target = e.target;
      return this.isActive && !this._isDestroyed && this.closeOnClick && !this.$refs.content.contains(target);
    },
    genActivatorAttributes: function genActivatorAttributes() {
      var attributes = Activatable.options.methods.genActivatorAttributes.call(this);
      if (this.activeTile && this.activeTile.id) {
        return _objectSpread(_objectSpread({}, attributes), {}, {
          'aria-activedescendant': this.activeTile.id
        });
      }
      return attributes;
    },
    genActivatorListeners: function genActivatorListeners() {
      var listeners = Menuable.options.methods.genActivatorListeners.call(this);
      if (!this.disableKeys) {
        listeners.keydown = this.onKeyDown;
      }
      return listeners;
    },
    genTransition: function genTransition() {
      var content = this.genContent();
      if (!this.transition) {
        return content;
      }

      // VMenuTransition
      return this.$createElement('transition', {
        name: this.transition,
        appear: true
      }, [content]);
    },
    genDirectives: function genDirectives() {
      var _this2 = this;
      var directives = [{
        name: 'show',
        value: this.isContentActive
      }];

      // Do not add click outside for hover menu
      if (!this.openOnHover && this.closeOnClick) {
        directives.push({
          name: 'click-outside',
          value: {
            handler: function handler() {
              _this2.isActive = false;
            },
            closeConditional: this.closeConditional,
            include: function include() {
              return [_this2.$el].concat(_toConsumableArray(_this2.getOpenDependentElements()));
            }
          }
        });
      }
      return directives;
    },
    genContent: function genContent() {
      var _this3 = this;
      var options = {
        attrs: _objectSpread(_objectSpread({}, this.getScopeIdAttrs()), {}, {
          role: 'role' in this.$attrs ? this.$attrs.role : 'menu'
        }),
        staticClass: 'v-menu__content',
        class: _objectSpread(_objectSpread(_objectSpread({}, this.rootThemeClasses), this.roundedClasses), {}, _defineProperty({
          'v-menu__content--auto': this.auto,
          'v-menu__content--fixed': this.activatorFixed,
          menuable__content__active: this.isActive
        }, this.contentClass.trim(), true)),
        style: this.styles,
        directives: this.genDirectives(),
        ref: 'content',
        on: {
          click: function click(e) {
            var target = e.target;
            if (target.getAttribute('disabled')) {
              return;
            }
            if (_this3.closeOnContentClick) {
              _this3.isActive = false;
            }
          },
          keydown: this.onKeyDown
        }
      };
      if (this.$listeners.scroll) {
        options.on = options.on || {};
        options.on.scroll = this.$listeners.scroll;
      }
      if (!this.disabled && this.openOnHover) {
        options.on = options.on || {};
        options.on.mouseenter = this.mouseEnterHandler;
      }
      if (this.openOnHover) {
        options.on = options.on || {};
        options.on.mouseleave = this.mouseLeaveHandler;
      }
      return this.$createElement('div', options, this.genScrollArea());
    },
    genScrollArea: function genScrollArea() {
      var _this$$device;
      if (process.client) {
        var _window;
        if ((_window = window) !== null && _window !== void 0 && (_window = _window.$nuxt) !== null && _window !== void 0 && (_window = _window.$device) !== null && _window !== void 0 && _window.hasTouch && !this.enablePerfectScrollBar) {
          return this.getContentSlot();
        }
      }
      if (this !== null && this !== void 0 && (_this$$device = this.$device) !== null && _this$$device !== void 0 && _this$$device.hasTouch && !this.enablePerfectScrollBar) {
        return this.getContentSlot();
      }
      return [this.$createElement('PerfectScrollbar', {
        staticClass: 'v-menu__scroll-area',
        props: {
          options: {
            wheelPropagation: false
          }
        }
      }, this.getContentSlot())];
    },
    getTiles: function getTiles() {
      if (!this.$refs.content) {
        return;
      }
      this.tiles = Array.from(this.$refs.content.querySelectorAll('.v-list-item'));
    },
    mouseEnterHandler: function mouseEnterHandler() {
      var _this4 = this;
      this.runDelay('open', function () {
        if (_this4.hasJustFocused) {
          return;
        }
        _this4.hasJustFocused = true;
      });
    },
    mouseLeaveHandler: function mouseLeaveHandler(e) {
      var _this5 = this;
      // Prevent accidental re-activation
      this.runDelay('close', function () {
        if (_this5.$refs.content.contains(e.relatedTarget)) {
          return;
        }
        requestAnimationFrame(function () {
          _this5.isActive = false;
          _this5.callDeactivate();
        });
      });
    },
    nextTile: function nextTile() {
      var tile = this.tiles[this.listIndex + 1];
      if (!tile) {
        if (!this.tiles.length) {
          return;
        }
        this.listIndex = -1;
        this.nextTile();
        return;
      }
      this.listIndex++;
      if (tile.tabIndex === -1) {
        this.nextTile();
      }
    },
    prevTile: function prevTile() {
      var tile = this.tiles[this.listIndex - 1];
      if (!tile) {
        if (!this.tiles.length) {
          return;
        }
        this.listIndex = this.tiles.length;
        this.prevTile();
        return;
      }
      this.listIndex--;
      if (tile.tabIndex === -1) {
        this.prevTile();
      }
    },
    lastTile: function lastTile() {
      var tile = this.tiles[this.tiles.length - 1];
      if (!tile) {
        return;
      }
      this.listIndex = this.tiles.length - 1;
      if (tile.tabIndex === -1) {
        this.prevTile();
      }
    },
    firstTile: function firstTile() {
      var tile = this.tiles[0];
      if (!tile) {
        return;
      }
      this.listIndex = 0;
      if (tile.tabIndex === -1) {
        this.nextTile();
      }
    },
    onKeyDown: function onKeyDown(e) {
      var _this6 = this;
      if (e.keyCode === keyCodes.esc) {
        // Wait for dependent elements to close first
        setTimeout(function () {
          _this6.isActive = false;
        });
        var activator = this.getActivator();
        this.$nextTick(function () {
          return activator && activator.focus();
        });
      } else if (!this.isActive && [keyCodes.up, keyCodes.down].includes(e.keyCode)) {
        this.isActive = true;
      }

      // Allow for isActive watcher to generate tile list
      this.$nextTick(function () {
        return _this6.changeListIndex(e);
      });
    },
    onResize: function onResize() {
      if (!this.isActive) {
        return;
      }

      // Account for screen resize
      // and orientation change
      // eslint-disable-next-line no-unused-expressions
      this.$refs.content.offsetWidth;
      this.updateDimensions();

      // When resizing to a smaller width
      // content width is evaluated before
      // the new activator width has been
      // set, causing it to not size properly
      // hacky but will revisit in the future
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = window.setTimeout(this.updateDimensions, 100);
    }
  },
  render: function render(h) {
    var _this7 = this;
    var data = {
      staticClass: 'v-menu',
      class: {
        'v-menu--attached': this.attach === '' || this.attach === true || this.attach === 'attach'
      },
      directives: [{
        arg: '500',
        name: 'resize',
        value: this.onResize
      }]
    };
    return h('div', data, [!this.activator && this.genActivator(), this.showLazyContent(function () {
      return [_this7.genTransition()];
    })
    // this.showLazyContent(() => [
    //     this.$createElement(VThemeProvider, {
    //         props: {
    //             root: true,
    //             light: this.light,
    //             dark: this.dark,
    //         },
    //     }, [this.genTransition()]),
    // ]),
    ]);
  }
});