import Vue from 'vue';

const handleError = (error, vm) => {
  console.error('[ERROR] errorHandler | onunhandledrejection:', error);

  if (vm?.$sentry?.captureException) {
    vm.$sentry.captureException(error, {
      tags: {
        level: 'App error',
      },
    });
  }

  if (vm?.$exception) {
    vm.$exception(error);
  }
};

Vue.config.errorHandler = (err, vm) => handleError(err, vm);
