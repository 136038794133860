import type { Context } from '@nuxt/types';
import {
  setGlobalPinia,
  useRealtyApp,
} from '@strana-artw/realty';

const piniaPlugin = ({ $pinia, app }: Context): void => {
  $pinia.use(({ store }: { store: any }) => {
    store.$router = app.router;
    store.$sentry = app.$sentry;
    store.$notify = app.$notify;
    store.$auth = app.$auth;
  });

  setGlobalPinia($pinia);
  useRealtyApp();
};

export default piniaPlugin;
