import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import type {
  Notifications,
  NotificationsResponse,
} from '@/types/Notifications';
import { useClientApi } from '@/plugins/api/lk';
import { accountApiConfig } from '@/config/api/account';
import { useSentry } from '@/plugins/sentry';

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Notifications>([]);

  async function getNotifications (): Promise<void> {
    try {
      const url = accountApiConfig.getCautions;
      const { data } = await useClientApi().get<NotificationsResponse>(url);
      const { warnings } = data;

      notifications.value = warnings ?? [];
    } catch (error) {
      console.error('🚀 ~ file: notification.ts ~~ getNotifications ~ e', error);
      useSentry().captureException(error);
    }
  }

  async function removeNotification (id: number): Promise<void> {
    try {
      const url = accountApiConfig.deleteCaution(id);
      await useClientApi().$delete(url);
      await getNotifications();
    } catch (error) {
      console.error('🚀 ~ file: notification.ts ~~ removeNotification ~ e', error);
      useSentry().captureException(error);
    }
  }

  return {
    notifications,
    getNotifications,
    removeNotification,
  };
});
