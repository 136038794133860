import { defineStore } from 'pinia';

import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';

import type { StateChanger } from 'vue-infinite-loading';
import { useNotify } from '@@/common/plugins/notify';
import { useUserRole } from '@@/shared/composables/useUserRole';
import type {
  Act,
  PreparedAct,
} from '@/types/Acts';
import type { Agency } from '@/types/Agencies';
import type { PageInfo } from '@/types/PageInfo';
import { useClientApi } from '@/plugins/api/lk';
import { accountApiConfig } from '@/config/api/account';

interface ActsResponse {
  count: number;
  page_info: PageInfo;
  result: Array<Act>;
}

interface State {
  currentAct?: Act;
  currentBooking?: number;
  acts: Array<PreparedAct | Act>;
  newAct?: PreparedAct;
  count: number;
  pageInfo: PageInfo | object;
}

async function notifyError (error: unknown, fallbackMessage?: string): Promise<void> {
  const notifier = useNotify();

  if (!notifier) {
    return;
  }

  const message = getErrorMessageTyped(error, fallbackMessage);

  await notifier.create({
    message,
    type: 'negative',
  });

  throw new Error(message);
}

export const useActsStore = defineStore('dealsActs', {
  state: (): State => ({
    currentAct: undefined,
    currentBooking: undefined,
    acts: [],
    newAct: undefined,
    count: 0,
    pageInfo: {},
  }),

  getters: {},

  actions: {
    async getOrganisationData (): Promise<Agency | undefined> {
      const fallbackMessage = 'Не удалось получить данные организации';

      try {
        const role = useUserRole();
        const {
          agencyGetContractData,
        } = accountApiConfig.agencies;
        const url = agencyGetContractData(role);

        const {
          data,
        } = await useClientApi().get<Agency>(url);

        return data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ getOrganisationData ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error, fallbackMessage);
      }
    },

    async updateOrganisationData (contractData: object): Promise<void> {
      const fallbackMessage = 'Не удалось обновить данные организации';

      try {
        const role = useUserRole();
        const {
          agencyFillContractData,
        } = accountApiConfig.agencies;
        const url = agencyFillContractData(role);

        await useClientApi().patch(url, contractData);
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ updateOrganisationData ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error, fallbackMessage);
      }
    },

    async getActs (params: object) {
      try {
        const role = useUserRole();
        const {
          acts,
        } = accountApiConfig.documents;
        const url = acts(role);

        const {
          data: {
            count,
            result,
            page_info: pageInfo,
          },
        } = await useClientApi().get<ActsResponse>(url, {
          params,
        });

        this.acts = result.map(act => prepareAct(act));
        this.count = count;
        this.pageInfo = pageInfo;
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ getActs ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState,
      }: {
        page: PageInfo;
        infiniteState: StateChanger;
      }): Promise<void> {
      try {
        if (!page?.next_page) {
          return;
        }

        const {
          data: {
            result,
            page_info: pageInfo,
          },
        } = await useClientApi().get<ActsResponse>(page?.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          const nextActs = result
            .map(act => prepareAct(act));

          this.acts = [
            ...this.acts,
            ...nextActs,
          ];

          if (!pageInfo?.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    },

    async createActs (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const {
          acts,
        } = accountApiConfig.documents;
        const url = acts(role);
        const {
          data,
        } = await useClientApi().post<Act>(url, {
          booking_id: id,
        });

        this.newAct = prepareAct(data);
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ createActs ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error);
      }
    },

    async getAct (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const {
          act,
        } = accountApiConfig.documents;
        const url = act(role, id);
        const {
          data,
        } = await useClientApi().get<Act>(url);

        this.currentAct = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: acts.ts ~ getAct ~ error', error);
        this.$sentry.captureException(error);

        const message = getErrorMessageTyped(error);
        throw new Error(message);
      }
    },

    setCurrentBooking (id: number): void {
      this.currentBooking = id;
    },

    async getActsByDeal (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const {
          acts,
        } = accountApiConfig.documents;
        const url = acts(role);
        const params = {
          bookings: id,
        };

        const {
          data: {
            count,
            result,
            page_info: pageInfo,
          },
        } = await useClientApi().get<ActsResponse>(url, {
          params,
        });

        this.acts = result.map(act => prepareAct(act));
        this.count = count;
        this.pageInfo = pageInfo;
      } catch (error) {
        console.log('🚀 ~ file: acts.ts ~ getActsByDeal ~ error', error);
        this.$sentry.captureException(error);
      }
    },
  },
});

function prepareAct (act: Act): PreparedAct {
  const {
    slug,
    files,
  } = act.files[0] ?? {};

  return {
    ...act,
    documentType: slug,
    fileName: files[0]?.name,
    files,
  };
}
