import {
  defineStore,
  storeToRefs,
} from 'pinia';
import {
  computed,
  ref,
} from '@nuxtjs/composition-api';
import { MENU_TYPE } from '@@/shared/constants/menuType';
import type { Image } from '@/types/Image';
import { useClientApi } from '@/plugins/api/lk';
import { useAuthStore } from '@/store/authStore';
import { useSentry } from '@/plugins/sentry';

interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: Image;
  hideDesktop: boolean;
}

interface MenuResult {
  top: Array<MenuItem>;
  right: Array<MenuItem>;
}

interface GetMenuResponse {
  result: MenuResult;
}

const excludeUrlByAggregator = ['/loyalty-program'];

const filteredMenu = (menu: Array<MenuItem>, isAggregator: boolean): Array<MenuItem> => {
  return menu?.filter(item => isAggregator
    ? !excludeUrlByAggregator.includes(item.link)
    : true);
};

export const useMenuStore = defineStore('menu', () => {
  const authStore = useAuthStore();
  const { isAggregator } = storeToRefs(authStore);

  const menu = ref<MenuResult>({
    top: [],
    right: [],
  });

  const headerMenu = computed(() => {
    return filteredMenu(menu.value.top, isAggregator.value);
  });

  const burgerMenu = computed(() => {
    return filteredMenu(menu.value.right, isAggregator.value);
  });

  async function getMenu (): Promise<void> {
    try {
      const url = 'api/v2/menu';
      const {
        result,
      } = await useClientApi().$get<GetMenuResponse>(url, {
        params: {
          city: 'tmn',
          menu_type: MENU_TYPE.LK_BROKER,
        },
      });
      menu.value = result;
    } catch (error) {
      console.error('🚀 ~ file: menu.ts ~ getMenu ~ e', error);
      useSentry().captureException(error);
    }
  }

  return {
    getMenu,
    headerMenu,
    burgerMenu,
  };
});
