import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.small.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';

// Mixins
import BindsAttrs from '../../mixins/binds-attrs';
import Colorable from '../../mixins/colorable';
import Sizeable from '../../mixins/sizeable';
import Themeable from '../../mixins/themeable';

// Util
import { convertToUnit, keys, remapInternalIcon } from '../../utils/helpers';
import mixins from '../../utils/mixins';
function isSvgPath(icon) {
  return /^[mzlhvcsqta]\s*[-+.0-9][^mlhvzcsqta]+/i.test(icon) && /[\dz]$/i.test(icon) && icon.length > 4;
}
var SIZE_MAP = {
  xxSmall: '6px',
  xSmall: '12px',
  small: '16px',
  little: '20px',
  default: '24px',
  medium: '24px',
  large: '36px',
  xLarge: '40px',
  xxLarge: '48px'
};
var VIcon = mixins(BindsAttrs, Colorable, Sizeable, Themeable).extend({
  name: 'VIcon',
  props: {
    dense: Boolean,
    disabled: Boolean,
    left: Boolean,
    right: Boolean,
    size: [Number, String],
    tag: {
      type: String,
      default: 'div'
    },
    name: {
      type: String
    },
    methods: {
      getIcon: function getIcon() {
        var iconName = '';
        if (this.$slots.default) {
          var _this$$slots$default$;
          iconName = (_this$$slots$default$ = this.$slots.default[0].text) === null || _this$$slots$default$ === void 0 ? void 0 : _this$$slots$default$.trim();
        } else if (this.name) {
          iconName = this.name;
        }
        return remapInternalIcon(this, iconName);
      },
      getSize: function getSize() {
        var sizes = {
          xSmall: this.xSmall,
          xxSmall: this.xxSmall,
          small: this.small,
          little: this.little,
          medium: this.medium,
          large: this.large,
          xLarge: this.xLarge,
          xxLarge: this.xxLarge
        };
        var explicitSize = keys(sizes).find(function (key) {
          return sizes[key];
        });
        return (
          // explicitSize ||
          // && SIZE_MAP[explicitSize]
          explicitSize && SIZE_MAP[explicitSize] || convertToUnit(this.size)
        );
      },
      // Component data for both font icon and SVG wrapper span
      getDefaultData: function getDefaultData() {
        return {
          staticClass: 'v-icon notranslate',
          class: {
            'v-icon--disabled': this.disabled,
            'v-icon--left': this.left,
            'v-icon--link': this.hasClickListener,
            'v-icon--right': this.right,
            'v-icon--dense': this.dense
          },
          attrs: _objectSpread({
            'aria-hidden': !this.hasClickListener,
            'disabled': this.hasClickListener && this.disabled,
            'type': this.hasClickListener ? 'button' : undefined
          }, this.attrs$),
          on: this.listeners$
        };
      },
      getSvgWrapperData: function getSvgWrapperData() {
        // const fontSize = this.getSize();
        var wrapperData = _objectSpread({}, this.getDefaultData());
        this.applyColors(wrapperData);
        return wrapperData;
      },
      applyColors: function applyColors(data) {
        data.class = _objectSpread(_objectSpread({}, data.class), this.themeClasses);
        this.setTextColor(this.color, data);
      },
      // renderFontIcon(icon, h) {
      //     const newChildren = [];
      //     const data = this.getDefaultData();
      //     let iconType = 'material-icons';
      //     // Material Icon delimiter is _
      //     // https://material.io/icons/
      //     const delimiterIndex = icon.indexOf('-');
      //     const isMaterialIcon = delimiterIndex <= -1;
      //     if (isMaterialIcon) {
      //         // Material icon uses ligatures.
      //         newChildren.push(icon);
      //     } else {
      //         iconType = icon.slice(0, delimiterIndex);
      //         // if (isFontAwesome5(iconType)) {
      //         //     iconType = '';
      //         // }
      //     }
      //     data.class[iconType] = true;
      //     data.class[icon] = !isMaterialIcon;
      //     const fontSize = this.getSize();
      //     if (fontSize) {
      //         data.style = { fontSize };
      //     }
      //     this.applyColors(data);
      //     return h(this.hasClickListener ? 'button' : this.tag, data, newChildren);
      // },
      renderSvgPath: function renderSvgPath(icon, h) {
        var _icon$split = icon.split('|'),
          _icon$split2 = _slicedToArray(_icon$split, 2),
          def = _icon$split2[0],
          viewBox = _icon$split2[1];
        var svgData = {
          class: 'v-icon__svg',
          attrs: {
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': viewBox !== undefined ? viewBox : '0 0 24 24',
            'role': 'img',
            'aria-hidden': true
          }
        };
        var size = this.getSize();
        if (size) {
          svgData.style = {
            fontSize: size,
            height: size,
            width: size
          };
        }
        return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h('svg', svgData, [def.split('&&').map(function (path) {
          var _path$split = path.split('@@'),
            _path$split2 = _slicedToArray(_path$split, 3),
            d = _path$split2[0],
            style = _path$split2[1],
            transform = _path$split2[2];
          return h('path', {
            attrs: {
              d: d,
              transform: transform
            },
            style: style
          });
        })])]);
      },
      renderSvgIcon: function renderSvgIcon(icon, h) {
        var svgData = {
          class: 'v-icon__svg',
          attrs: {
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 24 24',
            'role': 'img',
            'aria-hidden': true
          }
        };
        if (!this.disableInlineSize) {
          var size = this.getSize();
          if (size) {
            svgData.style = {
              fontSize: size,
              height: size,
              width: size
            };
          }
        }
        return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h('svg', svgData, [h('path', {
          attrs: {
            d: icon
          }
        })])]);
      },
      renderSvgIconComponent: function renderSvgIconComponent(icon, h) {
        var data = {
          class: {
            'v-icon__component': true
          }
        };
        if (!this.disableInlineSize) {
          var size = this.getSize();
          if (size) {
            data.style = {
              // fontSize: size,
              'height': size,
              'width': size,
              'min-width': size
            };
          }
        }
        this.applyColors(data);
        var component = icon.component;
        data.props = icon.props;
        data.key = icon.key;
        return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h(component, data)]);
      }
    },
    disableInlineSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // medium() {
    //     return false;
    // },
    hasClickListener: function hasClickListener() {
      return Boolean(this.listeners$.click || this.listeners$['!click']);
    }
  },
  methods: {
    getIcon: function getIcon() {
      var iconName = '';
      if (this.$slots.default) {
        var _this$$slots$default$2;
        iconName = (_this$$slots$default$2 = this.$slots.default[0].text) === null || _this$$slots$default$2 === void 0 ? void 0 : _this$$slots$default$2.trim();
      } else if (this.name) {
        iconName = this.name;
      }
      return remapInternalIcon(this, iconName);
    },
    getSize: function getSize() {
      var sizes = {
        little: this.little,
        xxSmall: this.xxSmall,
        xSmall: this.xSmall,
        small: this.small,
        medium: this.medium,
        large: this.large,
        xLarge: this.xLarge,
        xxLarge: this.xxLarge
      };
      var explicitSize = keys(sizes).find(function (key) {
        return sizes[key];
      });
      return (
        // explicitSize ||
        // && SIZE_MAP[explicitSize]
        explicitSize && SIZE_MAP[explicitSize] || convertToUnit(this.size)
      );
    },
    // Component data for both font icon and SVG wrapper span
    getDefaultData: function getDefaultData() {
      return {
        staticClass: 'v-icon notranslate',
        class: {
          'v-icon--disabled': this.disabled,
          'v-icon--left': this.left,
          'v-icon--link': this.hasClickListener,
          'v-icon--right': this.right,
          'v-icon--dense': this.dense
        },
        attrs: _objectSpread({
          'aria-hidden': !this.hasClickListener,
          'disabled': this.hasClickListener && this.disabled,
          'type': this.hasClickListener ? 'button' : undefined
        }, this.attrs$),
        on: this.listeners$
      };
    },
    getSvgWrapperData: function getSvgWrapperData() {
      // const fontSize = this.getSize();
      var wrapperData = _objectSpread({}, this.getDefaultData());
      this.applyColors(wrapperData);
      return wrapperData;
    },
    applyColors: function applyColors(data) {
      data.class = _objectSpread(_objectSpread({}, data.class), this.themeClasses);
      this.setTextColor(this.color, data);
    },
    // renderFontIcon(icon, h) {
    //     const newChildren = [];
    //     const data = this.getDefaultData();
    //     let iconType = 'material-icons';
    //     // Material Icon delimiter is _
    //     // https://material.io/icons/
    //     const delimiterIndex = icon.indexOf('-');
    //     const isMaterialIcon = delimiterIndex <= -1;
    //     if (isMaterialIcon) {
    //         // Material icon uses ligatures.
    //         newChildren.push(icon);
    //     } else {
    //         iconType = icon.slice(0, delimiterIndex);
    //         // if (isFontAwesome5(iconType)) {
    //         //     iconType = '';
    //         // }
    //     }
    //     data.class[iconType] = true;
    //     data.class[icon] = !isMaterialIcon;
    //     const fontSize = this.getSize();
    //     if (fontSize) {
    //         data.style = { fontSize };
    //     }
    //     this.applyColors(data);
    //     return h(this.hasClickListener ? 'button' : this.tag, data, newChildren);
    // },
    renderSvgPath: function renderSvgPath(icon, h) {
      var _icon$split3 = icon.split('|'),
        _icon$split4 = _slicedToArray(_icon$split3, 2),
        def = _icon$split4[0],
        viewBox = _icon$split4[1];
      var svgData = {
        class: 'v-icon__svg',
        attrs: {
          'xmlns': 'http://www.w3.org/2000/svg',
          'viewBox': viewBox !== undefined ? viewBox : '0 0 24 24',
          'role': 'img',
          'aria-hidden': true
        }
      };
      var size = this.getSize();
      if (size) {
        svgData.style = {
          fontSize: size,
          height: size,
          width: size
        };
      }
      return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h('svg', svgData, [def.split('&&').map(function (path) {
        var _path$split3 = path.split('@@'),
          _path$split4 = _slicedToArray(_path$split3, 3),
          d = _path$split4[0],
          style = _path$split4[1],
          transform = _path$split4[2];
        return h('path', {
          attrs: {
            d: d,
            transform: transform
          },
          style: style
        });
      })])]);
    },
    renderSvgIcon: function renderSvgIcon(icon, h) {
      var svgData = {
        class: 'v-icon__svg',
        attrs: {
          'xmlns': 'http://www.w3.org/2000/svg',
          'viewBox': '0 0 24 24',
          'role': 'img',
          'aria-hidden': true
        }
      };
      if (!this.disableInlineSize) {
        var size = this.getSize();
        if (size) {
          svgData.style = {
            fontSize: size,
            height: size,
            width: size
          };
        }
      }
      return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h('svg', svgData, [h('path', {
        attrs: {
          d: icon
        }
      })])]);
    },
    renderSvgIconComponent: function renderSvgIconComponent(icon, h) {
      var data = {
        class: {
          'v-icon__component': true
        }
      };
      if (!this.disableInlineSize) {
        var size = this.getSize();
        if (size) {
          data.style = {
            // fontSize: size,
            'height': size,
            'width': size,
            'min-width': size
          };
        }
      }
      this.applyColors(data);
      var component = icon.component;
      data.props = icon.props;
      data.key = icon.key;
      return h(this.hasClickListener ? 'button' : 'span', this.getSvgWrapperData(), [h(component, data)]);
    }
  },
  render: function render(h) {
    if (this.path && this.path.startsWith('M') === true) {
      return this.renderSvgPath(this.path, h);
    }
    var icon = this.getIcon();
    if (typeof icon === 'string') {
      if (isSvgPath(icon)) {
        return this.renderSvgIcon(icon, h);
      }
      // return this.renderFontIcon(icon, h);
    }
    return this.renderSvgIconComponent(icon, h);
  }
});
export default Vue.extend({
  name: 'VIcon',
  $_wrapperFor: VIcon,
  functional: true,
  render: function render(h, _ref) {
    var data = _ref.data,
      children = _ref.children;
    var iconName = '';

    // Support usage of v-text and v-html
    if (data.domProps) {
      iconName = data.domProps.textContent || data.domProps.innerHTML || iconName;

      // Remove nodes so it doesn't
      // overwrite our changes
      delete data.domProps.textContent;
      delete data.domProps.innerHTML;
    }
    return h(VIcon, data, iconName ? [iconName] : children);
  }
});