export default {
  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '1.4rem'
    }
  },
  computed: {
    componentObj: function componentObj() {
      if (this.href) {
        return {
          tag: 'a',
          props: {
            href: this.href
          }
        };
      }
      return {
        tag: 'n-link',
        props: {
          to: this.to
        }
      };
    },
    styles: function styles() {
      return {
        'height': this.fontSize,
        'font-size': this.fontSize
      };
    }
  }
};