import _readOnlyError from "@babel/runtime/helpers/esm/readOnlyError";
import "core-js/modules/es.function.name.js";
import { userNameWithInitialsFormatted } from '@@/common/utils/text-utils';
import { SIcon } from '@strana-artw/ui-kit';
import { roleToLabelMap } from '@@/shared/constants/roles';
export default {
  name: 'HeaderUserInfo',
  components: {
    SIcon: SIcon
  },
  computed: {
    user: function user() {
      var _this$$store$state$au = this.$store.state.auth,
        user = _this$$store$state$au.user,
        strategy = _this$$store$state$au.strategy;
      var _user = user,
        name = _user.name,
        surname = _user.surname,
        patronymic = _user.patronymic;
      return {
        role: roleToLabelMap[strategy],
        name: userNameWithInitialsFormatted(surname, name, patronymic)
      };
    }
  }
};