import qs from 'qs';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type createServer from 'connect';

export function processAxiosInstance (axios: NuxtAxiosInstance, req: createServer.IncomingMessage): void {
  axios.defaults.paramsSerializer = (params): string => qs
    .stringify(params, { arrayFormat: 'repeat' });
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  const headers = req?.headers ? ({ ...req.headers }) : {};
  axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host'] as string);
  axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port'] as string);
  axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto'] as string);
}
